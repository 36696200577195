(function(){window.i18nData={
    "global": {
        "opinautos": "StartMyCar",
        "opinautosDomain": "StartMyCar.com",
        "otherBrands": "Other Makes...",
        "writeUs": "Email us clicking here",
        "welcome": "Welcome",
        "commentsAboutTheSite": "Any thoughts on the website?",
        "thanks": "Thank you",
        "cancel": "Cancel",
        "close": "Close",
        "distanceUnit": "miles",
        "dateDistance": "{{distance}} ago",
        "loading": "Loading",
        "ok": "Ok",
        "saveChanges": "Save changes",
        "otherLanguages": {
            "es": {
                "otherLanguageName": "Español",
                "otherLanguageDomainUrl": "https://www.opinautos.com",
                "otherLanguageFlagImage": "/images/banderas/es.png",
                "modelProblemsLinkText": "Problemas del %s en español",
                "problemsUrl": "https://www.opinautos.com/%s/%s/defectos"
            },
            "pt": {
                "otherLanguageName": "Português",
                "otherLanguageDomainUrl": "https://www.opinautos.com.br",
                "otherLanguageFlagImage": "/images/banderas/br.png",
                "modelProblemsLinkText": "Problemas do %s em português",
                "problemsUrl": "https://www.opinautos.com.br/%s/%s/defeitos"
            }
        },
        "writeUsShort": "Contact us",
        "letUsKnow": "Let us know here",
        "sendDataCorrection": "Did you find any kind of error in the data?",
        "tellUsHere": "Tell us what we should fix or improve...",
        "generations": {
            "generationN": "Gen {{n}}",
            "restyle": {
                "0": "Pre-facelift",
                "1": "1st facelift",
                "2": "2nd facelift",
                "3": "3rd facelift",
                "4": "4th facelift",
                "5": "5th facelift",
                "6": "6th facelift"
            },
            "other": "Other versions"
        },
        "thankYouVeryMuch": "Thank you very much!"
    },
    "static": {
        "domain": "//static.startmycar.com",
        "images": {
            "logoSmall": "/images/startmycar-logo-small.png",
            "topQuickMarcas": "chevrolet\nvolkswagen\nford\nrenault\npeugeot\nfiat",
            "allMakesImageMapping": "abarth\nacura\nalfa-romeo\naro\naudi\naustin\nbaic\nbmw\nbuick\nbyd\ncadillac\nchangan\nchery\nchevrolet\nchrysler\ncitroen\ncupra\ndacia\ndaewoo\ndaihatsu\ndatsun\ndodge\ndongfeng\nds\nfaw\nferrari\nfiat\nford\nfoton\ngalloper\ngeely\ngenesis\ngmc\ngreat-wall\nhaval\nhonda\nhummer\nhyundai\nika\ninfiniti\nisuzu\niveco\njac\njaguar\njeep\nkia\nlada\nlancia\nland-rover\nlexus\nlifan\nlincoln\nlotus\nmahindra\nmaserati\nmazda\nmercedes-benz\nmercury\nmini\nmitsubishi\nnissan\noldsmobile\noltcit\nopel\npeugeot\nplymouth\npontiac\nporsche\nproton\nram\nrenault\nrover\nrenault-samsung\nsaab\nsantana\nsaturn\nscion\nseat\nskoda\nsmart\nssangyong\nsubaru\nsuzuki\nswm\ntata\ntesla\ntoyota\nvolkswagen\nvolvo\ndfsk\nbentley\nshineray\naston-martin\nholden\nhsv\nmg\ntriumph\nvauxhall\n",
            "allMakesImage": "/images/makes-medium-all-tiny.png",
            "logoLargeWhite": "/images/design2/startmycar-logo-white-large.png",
            "logoHeaderWhite": "/images/design2/startmycar-logo-white-header.png"
        }
    },
    "emailAddresses": {
        "help": "help@startmycar.com",
        "info": "info@startmycar.com",
        "problems": "problems@startmycar.com",
        "feedback": "feedback@startmycar.com",
        "professionals": "professionals@startmycar.com"
    },
    "facebook": {
        "solution": {
            "template": "{{shortTitle}}' was solved at StartMyCar, click here to read it!"
        },
        "firstAnswer": {
            "template": "{{actionAuthor}} replied to your problem '{{shortTitle}}...', click here to read it!"
        },
        "newAnswer": {
            "template": "New reply from {{actionAuthor}} in {{make}} {{model}} '{{shortTitle}}...', click here to read it!"
        }
    },
    "emails": {
        "welcome": {
            "helloAndWelcomeUser": "Hi <strong>%s</strong>, welcome to StartMyCar!",
            "thanksForJoining": "Thank you for joining :)",
            "clickToActivateUser": "To activate your user '<em>%s</em>' click this link:&nbsp;",
            "confirmRegistrationButton": "Confirm your registration by clicking here",
            "weWillEmailYou": "If someone answers your questions or asks for your help, we will <strong>send you an email</strong>!",
            "contactUs": "If you have any doubts, email us at <a href='mailto:%s'>%s</a>!",
            "weWillEmailYouOnFuseboxUpdates": "We are already working on your request for the fuse box diagram of your {{marca}} {{modelo}}! We will let you know when we have some update on this.<br><br>In the meantime, we invite you to read <a href={{url}} >what is being said in the community about your car fuses</a>.<br><br>You are in the perfect place to find what you are looking for."
        },
        "solution": {
            "subject": "'%s...' was solved in StartMyCar!",
            "markedAsSolved": "The %s %s problem has been marked as resolved!",
            "readButtonContent": "Read the solution!"
        },
        "newAnswer": {
            "subject": "There has been a new reply to '%s...' at StartMyCar!",
            "thereIsANewComment": "There has been a new reply in the problem of the %s %s!",
            "theUserWrote": "The user <strong>%s</strong> wrote:",
            "theUserWroteNoContent": "The user <strong>%s</strong> has replied.",
            "readAndReplyButton": "Read the entire thread and reply CLICKING here",
            "readAndReplyButtonNoContent": "Read the answer and reply CLICKING here"
        },
        "firstAnswer": {
            "subject": "Someone replied to '%s...' at StartMyCar!",
            "thereHasBeenAReply": "Someone has replied to your problem with your %s %s!",
            "theUserAnsweredYou": "The user <strong>%s</strong> wrote:",
            "theUserAnsweredYouNoContent": "The user <strong>%s</strong> has replied to you.",
            "ifSolvedShare": "If you solved your problem, please don't forget to SHARE HOW!"
        },
        "footer": {
            "didYouForgotPassword": "Did you forget your password at StartMyCar? ",
            "getInstructionsToReset": "Get instructions on how to get a new one.",
            "youCanAlso": "You can also ",
            "cancelEmailSuscription": "stop receiving these emails",
            "orChangeYour": " or change your ",
            "notificationsConfiguration": "notifications configuration.",
            "doYouNeedHelp": " Do you need help?",
            "ifYouDidntRegister": "If you don't know what this email is about, and you never registered at StartMyCar.com, click ",
            "notMyAccount": "here"
        },
        "passwordReset": {
            "subject": "Did you forget your password at StartMyCar?",
            "someoneAskForReset": "Someone has asked to reset the password of this account at StartMyCar.com",
            "ifItHasBeenYou": "If it was you, choose a new password by clicking the following link:&nbsp;",
            "pickNewPass": "Choose a new password",
            "ifNotYouIgnoreEmail": "If it wasn't you, you can safely ignore this email."
        },
        "common": {
            "opinautosTeamSignature": "StartMyCar's Team",
            "helloUser": "Hi <strong>%s</strong>,",
            "usernameOnlyGreeting": "%s,",
            "javierSignature": "Javier - StartMyCar's Team"
        },
        "emailConfirmation": {
            "subject": "%s, confirm your email address at StartMyCar!"
        },
        "campaigns": {
            "subject": "Hello {{username}}, any news about your {{marca}} {{modelo}}?",
            "authorDidntComeBack": {
                "peopleRepliedWhereAreYou": "Even though people replied to your report <em>'{{titulo}}'</em> of your {{marca}} {{modelo}}, <strong>we haven't heard back from you in a while!</strong> Did you find a solution to your problem?"
            },
            "noReplies": {
                "noRepliesYet": "We are sorry that your report <em>'{{titulo}}'</em> of your {{marca}} {{modelo}} hasn't been replied yet, but <strong>don't get discouraged!</strong>",
                "shareAnyNews": "If you have any update on the problem, or more details that could be helpful, share them so that other users can help you or at least learn from your experience."
            },
            "repliedByAuthorButUnsolved": {
                "clickHereToShareNews": "Check and comment on your report CLICKING here",
                "ifNotSolvedShareAnyNews": "If you haven't found a solution yet but you have an update, share it by writing a comment so that other members are able to keep helping you.",
                "ifSolvedMarkIt": "If you have solved the problem, help other users by commenting the details of how you did it and then click <em>'Accept as best answer'</em> on your own comment.",
                "yourProblemIsNotMarkedSolved": "We noticed that your report <em>'{{titulo}}'</em> of your {{marca}} {{modelo}} has not been marked as solved yet."
            },
            "contributor": {
                "variations": {
                    "subjects": {
                        "help": "{{username}}, see how many people you've helped on StartMyCar!",
                        "points": "{{username}}, your responses on StartMyCar keep adding points!",
                        "ranking": "{{username}}, you keep rising in StartMyCar's ranking!",
                        "contribute": "{{username}}, users in StartMyCar need you!"
                    },
                    "bodies": {
                        "help": "Your responses have helped many people, and there are many more still needing your help.",
                        "points": "Your responses have helped many people, come back and keep adding more points to your score.",
                        "ranking": "Your responses have helped many people, come back to answer and keep climbing positions in the user ranking.",
                        "contribute": "Your responses have helped many people, and there are many more still needing your help."
                    },
                    "buttons": {
                        "help": "Click here to see how much you've helped!",
                        "points": "Click here to see your score!",
                        "ranking": "Click here to see your ranking!",
                        "contribute": "Click here to keep helping!"
                    }
                }
            },
            "contribution": {
                "manualApproved": {
                    "body": "Your manual has been checked and published. <strong>Thank you! It's very helpful for the community 💙</strong>",
                    "subject": "Your {{modelo}} manual has been published 🚀",
                    "btnSeeManual": "See the manual"
                },
                "fuseboxImageApproved": {
                    "body": "Your fuse box photo has been verified. <strong>Thank you! It's very helpful for the community 💙</strong>",
                    "subject": "Your {{modelo}} fuse box photo has been verified 🚀"
                },
                "tirePressureLabelImageApproved": {
                    "body": "Your tire pressure label photo has been verified. <strong>Thank you! It's very helpful for the community 💙</strong>",
                    "subject": "Your {{modelo}} tire pressure label photo has been verified {{modelo}} 🚀"
                },
                "common": {
                    "oneMoreContrib": "This contribution awarded you points and made you go up in the ranking 🚀"
                }
            },
            "scoreChange": {
                "subject": "Congratulations {{username}}! You're moving up in StartMyCar's ranking",
                "content": "Your responses have helped many people, and there are many more still needing your help.",
                "link": "Click here to keep helping!",
                "yourCurrentRanking": "Your current ranking",
                "seeProfileAndStats": "See your profile and stats..."
            },
            "dataFeedback": {
                "fusebox": {
                    "requestInfo": {
                        "subject": "{{year}} {{model.modelo}} fuse box diagram",
                        "body": "We've been searching for your {{year}} {{modelo}} fuse box diagram, but we couldn't find the owner manual that usually contains it. <br/><br/><strong>If you have the manual for your {{modelo}}</strong>, search for \"fuse\" in the index. If you find it and <strong>reply to us with a picture</strong> of the manual, we can create the interactive diagram and send it to you; at the same time you'll be helping other owners that need it 😉 <br/><br/><strong>If you don't have the manual</strong>, sometimes the plastic cover of the fusebox has a diagram, if you send it to us we can check it out.",
                        "suggestedFusebox": "💡 We've seen that the picture you sent us is very similar to <a href='{{url}}' target='_blank'>this fuse box for the year {{suggestedYear}}</a>, so it might be useful to you. Tell us if it helped!"
                    },
                    "requestInfoSuggest": {
                        "subject": "{{year}} {{model.modelo}} fuse box diagram, this might help you"
                    },
                    "weFoundFusebox": {
                        "subject": "We published your {{year}} {{model.marca}} {{model.modelo}} fuse box diagram!",
                        "body": "It took us a while, but <strong>we found the fuse box diagram</strong> for your {{year}} {{modelo}} 😁<br/><br/>We've already published it, you can <a href='{{fuseboxUrl}}' target='_blank'>see it here</a>!<br/><br/>If you have any doubt or if you find an error, please write to us. The help of all the owners is key, so we invite you to keep contributing in our {{modelo}} community 🛠"
                    },
                    "alreadyHaveFusebox": {
                        "body": "We received the picture of your fuse box, sorry for the delay!</br><br/>We already have published <a href='{{url}}' target='_blank'>this fuse box diagram for the {{year}} {{modelo}}</a> that matches your picture.<br/> Do you have any question about it that you would like to ask us? Please tell us if it helped you!"
                    }
                },
                "customSubject": "{{subjectHtml}}"
            }
        },
        "spammerEngagement": {
            "ifMechanicThenRegisterAsSuch": "If you offer professional auto repair services and you wish to publish your contact information, <span style=\"color:MediumSeaGreen;\">you can register with us as a professional</span> and your information will be displayed next to useful comments you write on our platform.",
            "registerAsMechanicButton": "Click here to register as a professional",
            "youllBeBlocked": "If this behavior is repeated, you will be blocked.",
            "weHaveDetectedSpam": "We've detected your usage of our platform for publicity purposes. We will delete any comment that <ul style=\"color:Tomato;\"> <li>Does not provide useful information for other users.</li> <li>Only contains publicity or contact information (for example, <i>\"I can help, message me at 1122334455\"</i>).</li> </ul>",
            "subject": "WARNING: Inappropriate activity on StartMyCar"
        }
    },
    "messages": {
        "welcomeAfterAddProblem": {
            "whileYouGetHelp": "StartMyCar is a community of car owners helping each other:",
            "welcome": "Welcome to StartMyCar!"
        },
        "confirmationResent": {
            "weChangedYourEmail": "We changed your email address to <strong>'%s'</strong>, and sent you a confirmation link to verify it is yours!",
            "ifProblemsEmailUs": "If you don't receive the email, or for any other issue, drop us a line at <strong><a href='mailto:%s'>%s</a></strong>"
        },
        "feedbackSent": "<p>Thank you a lot for sharing your comments!</p><p>We'll try to read it and get back to you as soon as possible.</p>",
        "failedFacebookLogin": "<p>StartMyCar did not receive authorization from Facebook to login with your account.</p>",
        "keepHelping": "Keep helping:",
        "passwordReseted": "<p>The password was changed successfully!</p><br><p>Login to the website with your new password!</p>",
        "emailAlreadyExists": "<p>It means that <strong>in the past you have created an account</strong> at StartMyCar using the email <em>%s</em></p><p>Login with that user entering your password or Facebook:</p>",
        "defectoAgregado": "<p>Your problem was received successfully.</p><p>Thank you for sharing your experience, %s!</p>",
        "defaultSuccessTitle": "Success!",
        "thereWasAProblem": "There has been a problem",
        "thereWasAProblemWith": "There has been a problem with the ",
        "readOnlyModeMessage": "We are updating the website and to do so we have disabled this functionality for a few minutes, try again in 10 minutes. Sorry for the inconvenience :)",
        "defaultErrorPage": "There was a problem loading the page. If it persists, send us an email to %s",
        "problemNotFound": "Problem not found.",
        "pageNotFound": "Page not found.",
        "tagNotFound": "The category '%s' doesn't exist.",
        "failedFacebookLoginTitle": "Facebook login failed",
        "surveyFollowupSent": "<p>Thank you for your feedback, it helps us make a better website!</p> ",
        "vehicleNotFound": "The vehicle you are trying to edit does not exist",
        "userNotFound": "User not found",
        "commentNotFound": "Comment not found",
        "manualUploaded": "<strong>We received the file :)</strong><br><br>Thanks a lot for uploading this information.<br>StarMyCar wouldn't be possible without your help.<br><br><strong>THANK YOU</strong>",
        "userContribution": "<strong>Received!</strong><br><br>Thanks a lot for uploading this information.<br>StarMyCar wouldn't be possible without your help.<br><br><strong>THANK YOU</strong>",
        "more": {
            "doAndGetPoints": "You will also get points and go&nbsp;up&nbsp;<a href='{{url}}'>in&nbsp;the&nbsp;members&nbsp;ranking</a>",
            "doReplies": "Share your experience and help in other owner's problems",
            "doReports": "Report useless comments, aggressions and spam",
            "doReviewLeave": "Write a review",
            "doReviewOfYourVehicle": "of your vehicle to help potential new owners",
            "doVotes": "Upvote helpful replies to reward their authors",
            "whileYouGetAReplyHelp": "You can keep helping others",
            "dontForgetTo": "Also, don't forget to:"
        },
        "addReport": {
            "yourReportIsPublished": "Your post has been published in the community!",
            "youllGetEmailOnReply": "When someone replies we will email you",
            "anAnswerMayTakeSomeTime": "It may take a few hours or days to get an answer",
            "clickTheLinkSentTo": "Click the link we've sent to",
            "andActivateyourAccount": " to activate your profile in StartMyCar"
        },
        "addReview": {
            "yourReviewIsPublished": "Thanks! Your review has been published"
        },
        "welcomeAfterReply": {
            "thankYou": "Thanks <strong>{{user}}</strong>!<br> and welcome to StartMyCar",
            "weSentYouAnEmail": "You will receive an <strong>email</strong> from StartMyCar with a link, click it to confirm you are the owner of <strong>{{email}}</strong>"
        },
        "thankYouUser": "Thank you {{user}}!",
        "blacklisted": "The site can't be accessed",
        "checkYourCnx": "Is your internet working?"
    },
    "home": {
        "sloganManyCars": "Lots of people with the <strong>same car</strong>... and the <strong>same problems</strong>",
        "sloganShare": "Share. &nbsp; Help. &nbsp; Get help.",
        "howToStart": "How to start?",
        "pickBrand": "Pick the Make of your car:",
        "doubts": "Doubts? Questions? Comments?",
        "alreadyUser": "Already a user of %s?",
        "ifNew": "If you are new,",
        "canRegisterHere": "you can register here",
        "explanationInfographicAlt": "A community to help each other",
        "accountInfo": "Information about your account, your cars and your activity on the site",
        "howDoesItWork": "How does it work?",
        "linkContactUs": "Feedback",
        "linkPublishWithUs": "Publish with us",
        "linkLogin": "LOG IN",
        "linkPrivacyPolicy": "Privacy",
        "linkTermsOfUse": "Terms",
        "moreBrands": "SEE MORE MAKES",
        "searchPlaceholder": "Search for your problem or question...",
        "youCanAlsoNavigateByModel": "You can also search by make and model",
        "forExampleShort": "E.g.:&nbsp;",
        "step1Share": "Share your problem",
        "step1Explanation": "Describe your problem in detail, so that other members fo the community are able to help you. StartMyCar has <strong>thousands</strong> of reports of problems.",
        "step2GetHelp": "Get help",
        "step2Explanation": "We wanna help you solve your problem. In the process, we are also building a unique knowledge base of all the problems a vehicle might have, their causes and their solutions.",
        "step3ShareSolution": "Share the solution",
        "step3Explanation": "Once you solve your problem you can share how. Next time someone stumbles with the same problem, they'll be able to learn from your experience.",
        "step4BePart": "Join thousands of owners",
        "step4Explanation": "Help with answers, vote and share your knowledge. StartMyCar is built by car owners just like you!",
        "visitSiteInCountry": "Browse site version for:"
    },
    "misc": {
        "anonymous": "Anonymous",
        "or": "or"
    },
    "search": {
        "errorExplanation": "There was a problem executing the search :( Please try again in a couple of minutes",
        "searchboxPlaceholder": "Search problem...",
        "popularSearches": "Popular searches"
    },
    "forms": {
        "registration": {
            "forgotPassword": "I forgot my <br> password",
            "youLogIn": "Log in",
            "email": "Email",
            "logIn": "Log in",
            "password": "Password",
            "logOut": "Logout",
            "errorAddingUser": "There was a problem adding the user",
            "errorTitleEmailAlreadyExists": "%s already exists!",
            "errorEmailAlreadyExists": "There is already an account using that email.<br>If its you, instead of signing up again you should login using your password. You can do it by clicking the 'Login' button located in the upper right corner.",
            "errorUserAlreadyExists": "You already have an account with the user %s",
            "logIn2": "Log in",
            "doYouHaveAnAccount": "Do you have an account?"
        },
        "login": {
            "loginError": "Authentication failed. Check that the email and the password are correct.",
            "facebookLoginError": "There was a problem authenticating the user.",
            "facebookAuthFailed": "Authentication failed",
            "facebookNotRegisteredError": "You don't have an account on the website",
            "logInWithFacebook": "Login with Facebook",
            "forgotPassword": "Forgot your password?",
            "onlyForRegisteredUsers": "This page is for registered users only. If you already have an account, log in:",
            "signInWithFacebook": "Sign up with Facebook"
        },
        "passwordReset": {
            "unknownEmail": "The provided email does not belong to any known registered user. Check the spelling!",
            "requestExpired": "The request to reset the password has expired, make a new request.",
            "userNotFound": "The user whose password you are trying to reset does not exist.",
            "enterYourEmail": "Enter the email address you used to sign up:",
            "lookForTheConfirmationEmail": "Search for the email we have just sent to <em>%s</em> and <strong>click in the button</strong> that says <em>\"Confirm my account creation at StartMyCar\"</em>",
            "noEmailFilled": "<strong>You haven't entered an email</strong>",
            "pickANewPassword": "Choose a password to be able to login to your account later!",
            "saveNewPassword": "Save new password!",
            "sendRequest": "Send email to generate a new password",
            "sentExplanation": "<br>We have sent you an email with a link to choose a new password.<br> Check your inbox!",
            "userNotConfirmed": "The user has not been confirmed yet",
            "youAreNotConfirmedYet": "You don't have a password because you need to confirm your email first!"
        },
        "feedback": {
            "contentPlaceholder": "Any thoughts on the website? Feedback? Suggestions? Ideas? Write them all here!",
            "emailDescription": "Email to contact you (if you like)",
            "send": "Send feedback",
            "feedbackTooShort": "Your feedback is too short! Be more descriptive please!",
            "title": "Send us feedback about the website"
        },
        "emailconfirmation": {
            "invalidToken": "Invalid confirmation token",
            "alreadyConfirmed": "The user had been already confirmed.",
            "errorFailed": "Confirmation failed: %s",
            "errorInvalidLink": "Invalid confirmation link",
            "thanksForConfirming": "<strong>%s</strong>, thank you for confirming your email address."
        },
        "changeEmail": {
            "alreadyTaken": "That email address is already used by a another member. If it is yours, login with that user or ask for a password reset!",
            "userHadNoEmail": "The user had no previous email"
        },
        "vote": {
            "errorAlreadyVotedComment": "You have already voted that comment. You can't vote the same response more than once.",
            "errorSelfVoteComment": "You can't vote your own replies.",
            "errorAlreadyVoted": "You have already voted that problem. You can't vote the same problem more than once.",
            "errorSelfVote": "You can't vote your own problems."
        },
        "agregarDenuncia": {
            "errorInvalidProblemId": "Invalid problem id '%s'.",
            "errorDuplicate": "There is already a problem with exactly the same words:<br><br> <a href='%s'>%s</a><br><br>Is it possible that you may have already sent this problem?",
            "validations": {
                "editedTitleTooLong": "title is too long",
                "editedTitleTooShort": "title is too short",
                "editedContentTooShort": "content is too short",
                "editedContentTooLong": "content is too long"
            },
            "addProblem": "Submit",
            "contentPlaceholder": "Describe the problem or issue that you have.",
            "emailRequiredExplanation": "We need your email to let you know whenever someone replies and for the log-in in your account.",
            "ifRegistered": "If you already have an account, ",
            "loginWithIt": "login with it",
            "signature": "Authored by",
            "titlePlaceholder": "Title that describes your problem",
            "sectionAccount": "Your account",
            "sectionProblem": "Your problem",
            "sectionVehicle": "Your vehicle",
            "orAddANewVehicle": "or you can add a new vehicle",
            "sectionPickVehicle": "Select your vehicle",
            "toUseOtherVehicleClick": "(to use one of your existing vehicles click here)",
            "vehicleCountryPlaceholder": "Pick a country",
            "floatingAddText": "Ask question"
        },
        "responderDenuncia": {
            "errorDuplicate": "There is already a comment with exactly the same words!<br><br>If you don't see it, try reloading the page ;)",
            "submitReply": "Submit reply",
            "errorLocked": "The conversation on this report is closed for new replies.",
            "areaCommentPlaceholder": "Write your comment here",
            "areaReplyPlaceholder": "Write your reply here",
            "errorNoParent": "The comment you are trying to reply does not exist anymore",
            "errorSubSubComment": "You cannot reply a subcomment of a comment",
            "submitComment": "Submit comment",
            "notSolvedYet": "Still unresolved",
            "solvedIt": "I solved it",
            "solvedTheProblem": "I solved my problem or question"
        },
        "editComment": {
            "noChanges": "The comment is the same as before, you didn't change anything!",
            "errorInvalidIndex": "Invalid comment index",
            "areaPlaceholder": "Reply.",
            "errParentIsChild": "Can't add more than two levels depth",
            "errParentNewerThanChild": "The parent comment can't be newer than a child",
            "errParentNotFound": "The parent comment does not exists",
            "errParentSameAsChild": "A comment cannot be child of itself",
            "errCannotDestroyComment": "You can add what you need to the comment, but to delete or edit it completely write to us at <a href='mailto:moderators@startmycar.com'>moderators@startmycar.com</a>"
        },
        "editDenuncia": {
            "noChanges": "The problem is the same as before, you didn't change anything!",
            "checkAllFields": "Check that all fields have been filled out",
            "errorUserNotAuthorized": "You are not authorized to edit this problem",
            "errCannotDestroyDenuncia": "You can add what you need to the problem, but to delete or edit it completely write to us at <a href='mailto:moderators@startmycar.com'>moderators@startmycar.com</a>"
        },
        "common": {
            "topUserCountries": "us\nca\ngb\nau",
            "topCarCountries": "us\ngb\njp\nde\nmx\nkr\nfr",
            "carCountries": "ar\nau\nat\nbe\nbr\nca\ncn\ncz\nfr\nde\nhu\nin\nid\nir\nit\njp\nmy\nmx\nma\npe\npl\npt\nro\nru\nsk\nza\nkr\nes\nse\ntw\nth\ntr\ngb\nus\nuz",
            "userCountries": "au\nbs\nca\ngy\nin\nie\njm\nnz\nng\npk\nsg\nza\ntt\ngb\nus",
            "countryOther": "OTHER COUNTRY",
            "checkAllFields": "Check that all fields have been filled our properly ",
            "spellcheckLang": "en",
            "errorUserNotAuthorized": "Your user is not authorized for this operation"
        },
        "userRegistration": {
            "nicknameLabel": "Nickname",
            "nickNameExplanation": "It's the signature other users will see",
            "userCountryExplanation": "Your country",
            "emailLabel": "or with your Email",
            "emailLabelReply": "Your Email",
            "emailExplanation": "It's for managing your account and to let you know whenever someone replies or asks you a question",
            "userCountryPlaceholder": "Select your country"
        },
        "connectAccounts": {
            "errorCouldNotAuthenticate": "Could not authenticate the user. Check that the email and the password are both correct.<br><br>"
        },
        "formNoConfirmation": {
            "title": "If you didn't receive the confirmation email..."
        },
        "modelSelector": {
            "brandAndModelLabel": "Make and Model",
            "cantFindMyModel": "I can't find my Make or Model",
            "cantFindMyModelModalMsg": "Report that the following Make or Model is missing:",
            "carUsageExample": "E.g. 0, 250, 5000",
            "carUsageLabel": "Mileage",
            "carUsageUnit": "miles",
            "changeBrandOrModel": "Change <br> Model",
            "madeInLabel": "Made in",
            "modelExtrasExample": "E.g. hatchback, manual, 2.0L, etc ",
            "modelExtrasLabel": "Body / Engine / Other specs",
            "pickBrand": "Select a Make",
            "yearExample": "E.g. 2015, 2006, 1999...",
            "yearLabel": "Model year",
            "pickYear": "Pick a year"
        },
        "noConfirmation": {
            "check1": "Check that your email address is spelled correctly: <strong>%s</strong>",
            "check2": " Check that the email is not in the <strong>spam folder</strong>.<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(The email subject is '<em>Confirm your email address at StartMyCar</em>')",
            "check3": " Or else, send us an email to <strong><a href='mailto:%s'>%s</a></strong> with the subject '<em>Confirmation issue</em>'",
            "title": "If you did not get the confirmation email..."
        },
        "wrongEmail": {
            "myEmailIsNot": "My email is NOT <em>%s</em>",
            "title": "Fix email address",
            "updateEmail": "Update email",
            "errorSameEmail": "It's the same email as before, please fix it",
            "insteadItIs": "but instead"
        },
        "reportComment": {
            "areaReasonPlaceholder": "Explain why you want to report the comment...",
            "examples": "<ul><li>Aggressions, disrespect or mockery</li><li>Incorrect or dangerous suggestions</li><li>Spam, advertising or self promotion</li><li>Content out of place</li></ul>",
            "explanation": "Report this comment if it contains:",
            "report": "Report",
            "title": "Help us organize the community!"
        },
        "reportDenuncia": {
            "explanation": "Report this problem if it contains:",
            "title": "Help us organize the community!"
        },
        "setPassword": {
            "repeatPassword": "Repeat your password",
            "savePassword": "Save password",
            "title": "Choose a password for your account"
        },
        "formWrongEmail": {
            "title": "Check email address spelling"
        },
        "noAnswers": {
            "explanation": "StartMyCar is a community made up of <strong>people like you where everybody can ask and reply</strong>. We rely on <strong>car owners, enthusiasts and mechanics</strong> to provide their help and share information in order to help others. Unfortunately, sometimes it may take a while until someone sees a problem and is able to help. <br><br>Thank you for your understanding, and in the meantime, we encourage you to <strong>help in other questions</strong> where you can add value with your experience.",
            "title": "Why do some questions have no answers?"
        },
        "formSurveyFollowUp": {
            "title": "Tell us why"
        },
        "sameProblem": {
            "errorAlreadyMarked": "You are already following this problem",
            "errorAlreadyOwner": "You can't follow your own problem",
            "errorNothingToRemove": "You are not following the problem you are trying to unfollow"
        },
        "imageUpload": {
            "upload": "Click to upload image, or drag&nbsp;&&nbsp;drop",
            "dropzone": "Drag & drop images here",
            "dropzoneHover": "Drop images",
            "viewOriginal": "View original",
            "policy": {
                "title": "<h3>Image usage</h3>",
                "yesExplanation": "<strong>Do</strong> use images of the problem or solution to help others",
                "noExplanation": "<strong>Don't</strong> add profile images or images of your car unrelated to the discussion"
            },
            "uploadYourPhoto": "Upload your photo",
            "submitContrib": "Send contribution",
            "uploading": "Uploading..."
        },
        "pdfUpload": {
            "dropzone": "Drag and drop the file here",
            "dropzoneHover": "Drop the files",
            "submitFiles": "Upload files",
            "title": "Upload PDF manuals",
            "upload": "Click to select a PDF file or drag&nbsp;a&nbsp;file&nbsp;here"
        },
        "ShareMPG": {
            "explanation": "Share your MPG",
            "title": "Comparte tu rendimiento",
            "dontKnow": "I haven't measured it",
            "shareMpg": "Share"
        },
        "addReview": {
            "yourScore": "How would you score it?",
            "buttonNext": "Next",
            "buttonBack": "Back",
            "orAddANewVehicle": "add a new vehicle of yours",
            "sectionCreateUser": "Create a user",
            "sectionYourExperience": "In your experience:",
            "sectionLiked": "What you like the most",
            "sectionDisliked": "What you don't like",
            "likedPlaceholder": "What's the best about this vehicle?",
            "dislikedPlaceholder": "What's the worst about this vehicle?",
            "sectionYourVehicle": "Review your vehicle",
            "submitReview": "Submit review"
        },
        "switchCountry": {
            "nowYouAreInVersion": "Currently browsing StartMyCar's<br/>{{country}} version",
            "title": "Browse site version for"
        },
        "pickVehicle": {
            "whatYearIsYourModel": "What year is your {{model}}?",
            "whichOfThisVersion": "Which of these trims?",
            "other": "Other",
            "otherTrim": "Other version...",
            "whichVersion": "Which version?",
            "saveVehicle": "Save vehicle",
            "dontKnow": "Don't know",
            "otherMakeModel": "Other make or model..."
        },
        "contribFuseboxPicture": {
            "titleFindDiagram": "With a photo of your fuse box we can get the diagram",
            "titleHelpUs": "Help us improve the diagram with a photo of your fuse box",
            "modalTitleAskDiagram": "Request fuse box diagram"
        },
        "contribImage": {
            "popupBarTitle": "Add your contribution",
            "thanksForTheUpload": "Thank you for contributing!",
            "imageWillBeChecked": "Your photo is the first step in obtaining the diagram. We're already working on it; we'll do our best to have it ready as soon as possible 🔧",
            "anyExtraComment": "Anything else you wanna add?",
            "extraCommentPlaceholder": "Anything that may help us improve the diagrams...",
            "registerSoWeCanContactYou": "Create your account in StartMyCar.com so that we can get back to you ",
            "carProfileThx": "Thanks for sharing your photo 🔧",
            "tirePressureLabelThx": "We are going to process your picture and share the data with all the people asking for the right tire pressure 🔧"
        },
        "completeVehicleProfile": {
            "actionDesc": {
                "profile": "Select your car's model and trim",
                "profilePic": "Share a photo of your car",
                "review": "Write a review about your car: The best and the worst",
                "tirepressure": "Take a pic of your car's tire pressure label"
            },
            "title": "Complete your car's profile"
        },
        "pickMake": {
            "searchYourMake": "Pick your make",
            "whatMakeIsYourCar": "What make is your car?"
        },
        "pickModel": {
            "searchYourModel": "Search models",
            "whatModelIsYourCar": "What {{make}} model?"
        },
        "pickFuseboxProblem": {
            "FUSEBOX_NOT_FOUND": "There is no diagram",
            "FUSE_NOT_FOUND": "Can't find a fuse",
            "OTHER": "Other",
            "YEAR_NOT_FOUND": "There's diagram but not for my year",
            "didYouFindYourDiagram": "How can we help you?",
            "DIFFERENT_FUSES": "The fuses or their colors are too different"
        }
    },
    "tagging": {
        "catMechanic": "Mechanics",
        "catMechanicSlug": "mechanics",
        "catSecurity": "Safety",
        "catSecuritySlug": "seguridad"
    },
    "urls": {
        "compare": "compare",
        "problems": "problems",
        "pagePrefix": "page",
        "addNewProblem": "add",
        "pickBrand": "pickmake",
        "pickModel": "pickmodel",
        "auth": "auth",
        "addlogin": "addlogin",
        "facebook": "facebook",
        "registrationLogin": "registrationlogin",
        "userAlreadyExists": "registrationuserexists",
        "callback": "callback",
        "myAccount": "myaccount",
        "search": "search",
        "signup": "signup",
        "forgotPassword": "forgotpassword",
        "newPassword": "newpassword",
        "help": "help",
        "logout": "logout",
        "reviews": "reviews",
        "information": "info",
        "warningLights": "warning-lights",
        "ownersManual": "manuals",
        "fuseBox": "fusebox",
        "byDate": "latest",
        "byScore": "best",
        "userConfig": "config",
        "members": "members",
        "profile": "profile",
        "topByScore": "ranking",
        "professional": "professionals",
        "upload": "upload",
        "byNoAnswers": "no-answer",
        "showAll": "all",
        "guides": "guides",
        "add": "add",
        "topics": "topics",
        "trims": "trims",
        "myCar": "mycar",
        "ownersManualRepair": "service-repair",
        "community": "community",
        "symptomsWizard": "search-symptoms"
    },
    "validations": {
        "brandInvalid": "The selected Make is invalid.",
        "invalidModel": "invalid Model",
        "searchTermTooShort": "The search term is too short",
        "searchTermTooLong": "The search term is too long",
        "userEmail": "Check that the email is spelled correctly",
        "userCountry": "You have to select your country",
        "usernameTooShort": "You have to pick a username with at least 4 letters",
        "madeInCountryEmpty": "You have to select the country where your car is manufactured",
        "carYear": "You have to pick a model year",
        "carKms": "You have to enter the number of miles your vehicle has, even if it is just approximate",
        "titleTooShort": "The title has to be longer",
        "titleTooLong": "The title has to be shorter",
        "contentTooShort": "The content has to be longer and more complete",
        "smallContentTooShort": "Write at least 5 characters",
        "invalidDenunciaId": "Invalid attempt to reply.",
        "feedbackTooShort": "The feedback has to be longer and more complete",
        "passwordTooShort": "The password has to be at least 7 letters long",
        "passwordDontMatch": "Passwords don't match",
        "replyTooShort": "The comment has to be more descriptive: at least 20 characters.",
        "carKmsOnlyDigits": "The number of miles should be round, without decimals, dots or blank spaces",
        "noModel": "You have to select a model",
        "usernameTooLong": "The username cannot be longer than 35 characters!",
        "invalidCommentId": "Invalid attempt to reply.",
        "invalidImages": "The images sent are not valid",
        "imageUploadFailed": "There was a problem with the upload. Check your connection",
        "imageUploadFileTooLarge": "The image is too big (more than 15MB). Shrink it first",
        "imageUploadFileType": "The selected image format is not compatible with the site",
        "imageUploadInProgress": "Image upload is still in progress, resubmit when finished",
        "imageUploadTooManyFiles": "The maximum number of images allowed is 10",
        "invalidFiles": "The uploaded files are not valid",
        "pdfUploadFailed": "There was a problem with the upload. Check your connection",
        "pdfUploadFileTooLarge": "The size of the PDF file you are trying to upload exceeds the maximum allowed size of 20 MB",
        "pdfUploadFileType": "The selected file is not a PDF",
        "pdfUploadInProgress": "The upload is still in progress, resubmit when finished",
        "pdfUploadMustUploadSomething": "You have not selected any file",
        "pdfUploadTooManyFiles": "You can upload a maximum of 10 files",
        "starsMustRate": "Pick a score from 1 to 5 stars",
        "pickVehicle": "You have to select one of your vehicles",
        "invalidContribution": "There was a problem with the contribution"
    },
    "titles": {
        "searchModel": "Search problems and failures %s - StartMyCar",
        "pickBrand": "Pick vehicle Make - ",
        "pickModel": "Choose a model of ",
        "signUp": "Register - StartMyCar",
        "modelSummary": "%s %s information - StartMyCar",
        "problemsModelAdd": "Add a problem or issue %s %s - StartMyCar",
        "help": "Help - StartMyCar",
        "problemsModel": "%s %s problems and issues - StartMyCar",
        "problemsModelNew": "%s %s Problems and Solutions - StartMyCar",
        "problemsModelIndividual": "%s %s %s - StartMyCar",
        "problemsModelEdit": "Edit problem",
        "modelComparison": "%s %s comparison - StartMyCar",
        "modelComparisonVs": "%s %s vs %s %s - StartMyCar",
        "modelReviews": "{{make}} {{model}} reviews - StartMyCar",
        "modelWarningLights": "%s %s warning lights and indicators - StartMyCar",
        "modelOwnersManual": "%s %s owner's manual - StartMyCar",
        "modelOwnersManualYear": "{{year}} {{make}} {{model}} owner's manual - StartMyCar",
        "modelFuseBox": "{{make}} {{model}} fuse box diagrams for all years - StartMyCar",
        "modelFuseBoxYear": "{{year}} {{make}} {{model}} fuse box diagram - StartMyCar",
        "modelGuides": "{{make}} {{model}} useful guides",
        "myAccount": "My account - StartMyCar",
        "newPassword": "New password - StartMyCar",
        "problemsModelPage": "%s %s problems and issues - Page %s - StartMyCar",
        "problemsModelPageNew": "%s %s Problems and Solutions - Page %s - StartMyCar",
        "searchGlobal": "Find problems and issues - StartMyCar",
        "forgotPassword": "Forgot my password - StartMyCar",
        "logInOrSignUp": "Log in or sign up - StartMyCar",
        "problemsModelTag": "{tag} {make} {model} problems and issues - StartMyCar",
        "problemsModelTag_oldversion": "{tag} {make} {model} problems and issues - StartMyCar",
        "problemsModelTag_modeloSintoma": "{make} {model} {tag}: causes and solutions - StartMyCar",
        "problemsModelTag_modeloParte": "{make} {model} {tag}: problems and issues - StartMyCar",
        "userAddVehicle": "Add or edit a vehicle",
        "userConfig": "Account settings - StartMyCar",
        "memberProfile": "Profile of %s - StartMyCar",
        "membersTopByScore": "Top contributors - StartMyCar",
        "problemsCommentEdit": "Edit comment",
        "professionalProfile": "Shop: %s - StartMyCar",
        "modelOwnersManualUpload": "Upload %s %s owner's manual - StartMyCar",
        "modelTrims": "{{make}} {{model}} trims in {{country}}",
        "modelTrimsNoCountry": "{{make}} {{model}} trims",
        "modelOwnersManualRepair": "Service and repair manuals for {{make}} {{model}} - StartMyCar",
        "modelCommunity": "{{make}} {{model}} community - StartMyCar",
        "searchBySymptom": "Vehicle symptoms - StartMyCar",
        "modelFuseBoxIndividualFuse": "{{year}} {{make}} {{model}} {{fuse}} - StartMyCar"
    },
    "views": {
        "denunciasTagsResumen": {
            "popupContent": "<strong>%s%%</strong> of the problems of the car (%s reports) mentioned <strong>%s</strong> problems. <div><a href='%s' class='btn'>See reports</a></div>",
            "popupTitle": "<strong>%s</strong> problems",
            "otherProblems": "less common problems...",
            "allProblems": "All problems",
            "seeAllCategories": "+ Expand all categories",
            "seeLessCategories": "- Collapse categories",
            "moreSpecificProblems": "More specific problems:",
            "seeOtherTypeOfProblems": "Other problems..."
        },
        "denunciaHeadline": {
            "readMore": "Read..."
        },
        "help": "help/about-en",
        "privacyPolicy": "legal/privacy-policy-en",
        "termsOfUse": "legal/terms-of-use-en",
        "userPanel": {
            "areYouNew": "Are you new?",
            "signUp": "Sign up",
            "myAccount": "My account",
            "logOut": "Log out",
            "logInOrSignUp": "Log in or register",
            "createAccount": "Create your account"
        },
        "registracion": {
            "youAreAlreadyRegistered": "You are already registered!<br/>Your username is '<strong>%s</strong>'",
            "seeAccountInfo": "View account information",
            "join": "Join StartMyCar!",
            "title": "Create an account at StartMyCar"
        },
        "registracionOauth": {
            "title": "There's no user associated with that Facebook account",
            "didYouHaveAnAccount": "Have you already registered?",
            "nope": "No...",
            "yep": "Yes...",
            "ifYouAreNewCreateAnAccount": "If you're new and not registered, create an account clicking here:",
            "createAnAccount": "Create an account",
            "ifHadAnAccountConnect": "If you already have an account and you want to associate it to this Facebook account, enter the email and password and click \"Connect with my Facebook account\"",
            "connectWithFacebook": "Connect with my Facebook account"
        },
        "registracionOauthEmailTaken": {
            "title": "There's a user already registered with the email '%s' <br>",
            "sameFacebookEmail": "The same email is used by your Facebook account",
            "ifHadAnAccountWithEmail": "If you already have an account with the email '%s' and you want to associate it to this Facebook account, enter the account password and click \"Connect with my Facebook account\"",
            "ifForgotPassword": "If you forgot your password, click here:"
        },
        "selectorMarcaModelo": {
            "errorLoadingModels": "There was a problem loading models: ",
            "pickModelOf": "Choose a model of "
        },
        "userNotices": {
            "needToPickPassword": "<strong>%s</strong>&nbsp; You need to choose a password for your account&nbsp;",
            "pickPassword": "Choose a password",
            "weSentYouAConfirmationEmail": "We sent you an email to <strong>%s</strong> to confirm your new account!&nbsp&nbsp",
            "addressIsCorrect": "I didn't get the email",
            "addressIsNotRight": "My email has a typo",
            "passwordSaved": "The password was saved successfully"
        },
        "denuncia": {
            "accordingToAuthor": "&nbsp; (according to %s, problem author)&nbsp;",
            "anonymousUser": "anonymous user",
            "NHTSAAuthor": "NHTSA consumer report",
            "bestSolution": "Best solution",
            "deleteComment": "Delete",
            "downvoteTitle": "Downvote for unhelpful",
            "edit": "Edit",
            "delete": "Delete",
            "confirmDelete": "Are you sure you want to delete your problem?",
            "confirmCommentDelete": "Are you sure you want to delete your comment?",
            "flagInnapropiate": "Report inappropriate",
            "madeIn": "Made in %s",
            "markAsSolution": "Accept as best answer",
            "markedAsBestSolution": "Selected by the author as best answer",
            "readFull": "Read complete",
            "readMore": "Read more",
            "replies": {
                "one": "reply",
                "other": "replies"
            },
            "reply": "Reply",
            "reportComment": "Report comment",
            "shareYourProblem": "Describe your problem",
            "solvedAlt": "Solved",
            "unmarkAsSolution": "Deselect as best answer",
            "upvoteTitle": "Upvote for helpful",
            "upvoteBtn": "Vote helpful",
            "vote": {
                "one": "vote",
                "other": "votes"
            },
            "answersHeader": "Answers from the community",
            "noAnswersYet": "There are no answers yet. &nbsp;",
            "why": "Why?",
            "hadTheSameProblem": "Same issue here",
            "lockedMsg": "This conversation on this report is closed for new replies.",
            "sameProblem": {
                "one": "one more person with the same problem",
                "other": "and <strong class='n'>%s</strong> others with the same problem"
            },
            "comment": "Comment",
            "contactProfessional": "Contact professional",
            "filterModelsOfYear": "Models of year <strong>%s</strong>",
            "seeOtherYears": "See&nbsp;all&nbsp;years",
            "bestSolutionAccordingTo": "Best answer (according to %s)",
            "fromCountry": " from %s",
            "readHowWasSolved": "Read how",
            "sameProblemCounter": {
                "one": "<span class='js-sameproblem-count'>%s</span> users",
                "other": "<span class='js-sameproblem-count'>%s</span> users"
            },
            "solved": "Solved",
            "cases": {
                "one": "report",
                "other": "reports"
            },
            "helps": {
                "one": "suggestion",
                "other": "suggestions"
            },
            "solutions": {
                "one": "solution",
                "other": "solutions"
            },
            "otherOfftopicProblems": "Comments about other issues",
            "seeMoreOfftopicProblems": "Read {{count}} more unrelated comments..."
        },
        "denunciaEntera": {
            "dateAndReportedBy": "Written %s by",
            "problemCategorizedAs": "Tagged as: &nbsp;",
            "problemsWithModel": "Problems with your %s %s?",
            "shareYourExperience": "Share your experience"
        },
        "elegirMarca": {
            "allBrands": "All Makes:",
            "mostCommonBrands": "Most common Makes:",
            "pickABrand": "Choose a make:"
        },
        "elegirModelo": {
            "allModels": "All Models:",
            "mostCommonModels": "Most popular:",
            "pickAModel": "Choose a <strong>%s</strong> model:"
        },
        "errorPage": {
            "thereWasAProblem": "There was a problem with the page"
        },
        "formReportDenuncia": {
            "title": "Help us organize the community!"
        },
        "login": {
            "logInAsOtherUser": "log in with a different account",
            "orYouCan": " or you can ",
            "thisAccountsInfo": "account information",
            "youAreLoggedAs": "You are logged in as '%s'. You can view ",
            "newUser": "New user?"
        },
        "modeloComparar": {
            "otherModel": "other model...",
            "pickAModel": "Choose a model",
            "problems": "Problems and issues",
            "usuallyComparedWith": "Usually compared with:",
            "vs": "VS"
        },
        "modeloDefectos": {
            "mostCommonProblems": "Most common problems:",
            "problemsByCategory": "Problems by category",
            "mostRecentProblems": "Latest problems",
            "problemsOf": "<span class='marca'>%s</span> <span class='modelo'>%s</span> problems and issues",
            "problemsWithA": "Problems with a %s?",
            "reportedProblems": " There are <span class='resultCount'>%s</span> reported problems",
            "shareAndGetHelp": "Share them and get help!",
            "shareThem": "Share them",
            "otherTagProblems": "Other %s problems",
            "problemsWithNoAnswers": "Problems without answers",
            "seeProblemsWithNoAnswers": "see without answers",
            "seeOwnersManual": "Get owner's manual",
            "beMoreSpecific": "More specific problems:",
            "relatedProblems": "Related problems:",
            "otherTypeOfProblems": "Other problems"
        },
        "modeloDefectosPorTipo": {
            "problemsOfType": "<span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span> <span id='currentTag'>{{tag}}</span> problems and issues",
            "problemsOfTypeShort": "<span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span> <span id='currentTag'>{{tag}}</span> problems",
            "thereAreNReports": "There are <strong>%s</strong> reported problems",
            "allProblems": "All problems",
            "bestProblems": "Top problems",
            "latestProblems": "Latest problems",
            "seeBestProblems": "see top problems",
            "seeLatestProblems": "see latest problems",
            "seeAllProblems": "See {{count}} {{tag}} problems",
            "problemsOfTypeShortV2": "<span id='currentTag'>{{tag}}</span> problems",
            "bestProblemsOf": "Top %s problems",
            "seeOwnersManual": "See owner's manual",
            "seeFuseboxDiagram": "See fuse box diagram",
            "tagsWithFusebox": [
                "fuses",
                "relays"
            ]
        },
        "modeloResumen": {
            "addedProblems": " reported problems and issues",
            "problems": "Problems and issues",
            "compareModelWith": "Compare <strong>%s %s</strong> with another model...",
            "noReportsYet": "Nobody has shared a problem or experience yet.",
            "notComparedYet": "Nobody has compared this model yet.",
            "people": "people",
            "seeMoreProblems": "View more reported problems and issues...",
            "shareYourExperience": "Share your problem or experience",
            "wannaBeTheFirst": "Do you want to be the first?",
            "warningLights": "The most common %s warning lights explained",
            "ownersManual": "Download the owner's manual of your %s&nbsp;%s",
            "fuseBox": "Explore an interactive fuse&nbsp;box&nbsp;diagram of your %s %s",
            "guides": "Oil change, timing belt, check engine light and more",
            "compareWithOtherModel": "Compare with other model",
            "modelOverviewOf": "%s&nbsp;%s information",
            "ofProblemsAndSolutions": " of problems and solutions from other car owners ",
            "reports": "reports",
            "searchYourProblem": "Search your problem",
            "seeMore": "See more",
            "solveYourProblem": "get help",
            "reviewsDescription": "What real owners think of the {{model}}. The good things and the bad ones",
            "serviceManual": "Service and repair manuals, diagrams and more"
        },
        "myAccount": {
            "errorCannotAddVehicle": "This functionality is still under construction, sorry for that :(",
            "linkModelInfo": "Useful information",
            "pickAPassword": "You didn't choose a password yet. Enter one:",
            "problems": {
                "goToProblem": "View problem",
                "problemNotSolved": "Problem not solved yet",
                "problems": "Problems",
                "without": "without",
                "addProblem": "Share a problem or a question",
                "newProblem": "Report a problem",
                "updateProblem": "Post an update"
            },
            "title": "Your profile at StartMyCar",
            "userData": {
                "edit": "Change information...",
                "updatingData": "Updating information...",
                "youAreUsingFBLogin": "You are logged in with the Facebook account \"<strong>%s</strong>\"",
                "yourCountryIs": "Your country is",
                "yourData": "Your information",
                "yourEmailIs": "Your email address is",
                "yourNameIs": "Your public username is",
                "country": "Country",
                "editProfile": "Edit profile",
                "nickname": "Nickname",
                "yourCountry": "Your country"
            },
            "vehicles": {
                "addVehicle": "Add a vehicle",
                "madeIn": "Made in ",
                "modelYear": "Model ",
                "youHaveNoVehicles": "You have no vehicles",
                "yourVehicles": {
                    "one": "Your vehicle",
                    "other": "Your vehicles"
                },
                "addNewVehicle": "Add vehicle"
            },
            "contributions": {
                "completeProfileCallToAction": "Complete your profile",
                "profileCompletionProgress": "Profile <strong>{{coverage}}%</strong> complete",
                "seeAll": "See all my responses",
                "youHaveNoContributions": "You have not replied in any conversation yet",
                "yourComments": "Your latest replies",
                "yourContributions": "Your contributions",
                "approved": "APPROVED",
                "pending": "PENDING",
                "rejected": "REJECTED",
                "duplicated": "DUPLICATED",
                "descPdf": "{{count}} files ({{fileNames}})",
                "carProfileImage": "Car's profile picture",
                "carProfileIntro": "Show the best look of your {{model}}",
                "fuseboxImage": "Fuse box picture",
                "tirePressureImage": "Tire pressure label",
                "tirePressureIntro": "Look for the tires pressure label in the <strong>driver's door or the gas fill cap</strong>"
            },
            "linkUserConfig": "Notification settings",
            "stats": {
                "noActivty": "You don't have any comment or contribution yet"
            }
        },
        "relatedProblems": {
            "inOtherModels": "In other models",
            "relatedProblems": "Related problems"
        },
        "resetPasswordExpired": {
            "description": "Your password reset request has expired (it's been more than 24 hours)",
            "makeNewRequest": "Make a new request clicking here"
        },
        "searchResults": {
            "youSearched": "Results for ",
            "searchInstead": "Search instead for ",
            "resultsFound": "<strong>{{count}}</strong> related reports:",
            "noResultsFound": "No related reports found"
        },
        "selectorModelo": {
            "allModels": "All models:",
            "mostPopular": "Most popular:"
        },
        "infoOwnersManual": {
            "downloadPDF": "Download PDF",
            "title": "<span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span> owner's manuals",
            "findBelowExplanation": "Below you can find links to download for free the owner's manual of your {{make}} {{model}}.<br> Manuals from {{minYear}} to {{maxYear}}.",
            "currentlyDoesntExist": "Currently we don't have manuals for the {{make}} {{model}}.",
            "modelOwnersManual": "{{make}} {{model}} <strong>{{year}}</strong> Owner's Manual",
            "forTrims": "For {{year}} {{model}} {{trims}}",
            "lookingForOther": "Looking for another year or model?&nbsp;",
            "letUsKnow": "Let us know!",
            "canYouContribute": "Do you have any PDF manual to share?&nbsp;",
            "uploadIt": "Upload it and share it",
            "currentlyDoesntExistYear": "Currently we don't have manuals for the {{year}} {{make}} {{model}}.",
            "downloadManualInPDF": "Download manual PDF",
            "editionFromCountry": "{{country}} edition",
            "titleYear": "{{year}} <span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span> owner's manuals",
            "totalPages": "{{pages}} pages",
            "modelOtherManual": "{{category}} {{make}}&nbsp;{{model}} {{year}}",
            "titleRepairOther": "Service and repair manuals for <span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span>",
            "manualInSections": "Manual is divided into {{count}} sections",
            "manualEditions": {
                "one": "View a past edition of this manual",
                "other": "View {{count}} past editions of this manual"
            },
            "sectionNoName": "Other"
        },
        "infoFusebox": {
            "title": "<span class='marca'>{{make}}</span> <span class='modelo'>{{model}}</span> fuse box and relays diagrams",
            "titleYear": "{{year}} {{make}} {{model}} fuse box diagram",
            "yearDifferentFuseboxes": "This {{model}} has {{count}} different fuse boxes:",
            "diagramBoxName": "{{box}} diagram",
            "pickYearReminder": "{{make}} {{model}} fuse box diagrams change across years, pick the right year of your vehicle:&#32;",
            "relayNo": "Relay",
            "fuseNo": "Fuse no.",
            "fusetableHeaderNo": "No.",
            "fusetableHeaderType": "Type",
            "fusetableHeaderDescription": "Description",
            "source": "Source",
            "tapForMoreFuseInfo": "<strong>Hover or tap over a fuse</strong> to see more information",
            "exploreInteractiveFusebox": "Explore interactive fuse box and relay diagrams for the {{make}} {{model}}. Fuse boxes change across years, pick the year of your vehicle:&#32;",
            "fuseboxDiagramYear": "Fuse&nbsp;box&nbsp;diagrams<br/><span class='year'>{{year}} {{model}}<br/><span class='other'>{{other}}</span></span>",
            "feedbackMistakeInvitation": "Found a mistake? &nbsp;",
            "askForYourFusebox": "Is your car or year missing?",
            "wrongFusebox": "Doesn't match your car?",
            "askForItWeHelpYou": "Ask for your diagram",
            "letUsKnow": "Let us know!",
            "onboardingTouchFuse": "👆 Touch a fuse to see what it does.<br/><br/>↓ See full table below",
            "onboardingTouchTable": "👆 Touch a fuse from the list to locate it on the diagram",
            "nofuseboxYet": "We haven't published any diagrams for the <strong>{{make}} {{model}}</strong> yet, but upload a picture of your fuse box and we'll get it for you"
        },
        "infoWarningLights": {
            "title": "<span class='marca'>{{make}}</span> <span class='modelo'>{{model}}</span> warning lights explained",
            "readRelatedReportsSingular": "Read <span><b>{{tagCount}}</span> report</b> of issues with the <span>{{model}}</span> &nbsp",
            "readRelatedReportsPlural": "Read <span><b>{{tagCount}}</span> reports</b> of issues with the <span>{{model}}</span> &nbsp",
            "getHelpWith": "<b>Get help</b> with a problem involving the &#32",
            "pickALight": "Pick a light to see its meaning:",
            "getHelpWithWarningLight": "<b>Get help</b> with a problem involving the &#32",
            "infoSource": "Source: <span class='marca'>{{make}}</span> <span class='modelo'>{{model}}</span> owners manual (1999, 2008 and 2014)",
            "lookingForSomethingElse": "<b>Looking for another warning light or model?</b>&nbsp;&nbsp;",
            "letUsKnow": "Let us know!"
        },
        "infoTirePressure": {
            "accordingToSpecs": "{makeLogo}&nbsp;&nbsp;<span class=\"align-middle\">Detailed pressures</span>",
            "validForOriginalTires": "* Only original tires",
            "spareTire": "Spare tire",
            "front": "front",
            "rear": "rear",
            "summaryTitle": "{makeLogo}&nbsp;&nbsp;<span class=\"align-middle\">Tire pressure summary {makeOnly} {makeOrVehicle} </span>&nbsp; {countryFlag}",
            "defaultPressureUnit": "psi",
            "conditions": {
                "highway": "On highways",
                "no-highway": "On streets",
                "loadHigh": "With high load",
                "loadNormal": "With normal load",
                "trailer": "With trailer",
                "all": "",
                "comfort": "More comfort",
                "economic": "Fuel economy"
            }
        },
        "userConfig": {
            "emailCheckboxEngagement": "Receive reminders, user statistics and other relevant information periodically",
            "emailCheckboxTransactional": "Receive solutions and replies to your problems and comments",
            "title": "Email notifications"
        },
        "memberPublicProfile": {
            "title": "Public Profile",
            "originCountry": "From",
            "publicNickname": "Nickname",
            "userSince": "Joined the community",
            "memberSince": "Member since ",
            "userVehicles": {
                "one": "User vehicle",
                "other": "User vehicles"
            },
            "hisReplies": "Replies",
            "hisReports": "Reports",
            "negativeVotes": "Negative votes",
            "numberOfReplies": "Number of replies",
            "numberOfReports": "Number of reports",
            "positiveVotes": "Votes",
            "statistics": "Statistics",
            "moreInformation": "more information",
            "offersProfessionalServices": "&nbsp;Offers professional services",
            "denunciasPlusOnes": "Plus ones",
            "replies": "Comments",
            "solutions": "Solutions",
            "position": "Position",
            "seeTop100": "See top 100",
            "usersRanking": "Member's Ranking",
            "hideRankings": "Hide rankings",
            "showMoreRankings": "Your ranking in {{count}} more models...",
            "showMoreRankingsPublic": "Watch user ranking for {{count}} more models...",
            "usersRankingTwoLines": "Member's<br/>ranking",
            "stats": {
                "contribs": {
                    "one": "contribution",
                    "other": "contributions"
                }
            }
        },
        "membersTopByScore": {
            "headerCountry": "Country",
            "headerMemberAge": "Member since",
            "headerRank": "Ranking",
            "headerScore": "Score",
            "headerUser": "Name",
            "headerBestAnswersAndVotes": "Solutions and votes",
            "title": "Ranking of StartMyCar top contributors",
            "titleModel": "{{make}} {{model}}: Top 100 members with more contributions",
            "seeTop1000": "See top 1000"
        },
        "professionalProfile": {
            "address": "Address",
            "contactPerson": "Contact person",
            "country": "Country",
            "phone": "Phone number",
            "professional": "Professional: %s",
            "seeCompleteProfile": "See full profile...",
            "seeContactData": "See contact information",
            "shop": "Shop:",
            "speciality": "Speciality",
            "user": "User&nbsp;",
            "userProfile": "User profile in StartMyCar",
            "completeTheForm": "Complete this form with your information",
            "wantToAppearAsProfessional": "Do you want to be listed as a professional?",
            "orWriteUs": "Or write us to <a href='professionals@startmycar.com'>professionals@startmycar.com</a>"
        },
        "articles": {
            "relatedProblems": "{{make}} {{model}} problems: {{article}}",
            "articlesIndexTitle": "{{make}}&nbsp;{{model}} useful guides",
            "readMoreAboutMakeAndTopic": "Read more about {{make}} <br> and {{topic}}",
            "readMoreAboutTopic": "General information about<br> {{topic}} in a car",
            "relatedArticles": "Related guides",
            "makeModelsWithMoreProblems": "{{make}} models with more {{topic}} problems",
            "makeProblemsCount": "{{count}} problems",
            "haveYouFoundAnError": "Have you found an error in this guide? Let us know",
            "feedbackProblemsWithThisGuide": "Problems with this guide?",
            "feedbackProblemsWriteHere": "Write to us here...",
            "templateVehicle": "  car",
            "templateManualLinkClickHere": "click here",
            "templateManualLinkOwnerManual": "owner's manual",
            "articleNotFound": "Can't find guide '{{article}}'"
        },
        "filters": {
            "filters": "Filters"
        },
        "modelReviews": {
            "doYouOwnOne": "Do you own one?",
            "errorYouAlreadyMadeAReview": "You can only leave one review for each vehicle you own, and you have already left a review for your {{model}}.<br><br><a href='{{reviewUrl}}'>You can read that previous review here</a>",
            "leaveYourReview": "Write a review",
            "subtitle": "Latest owner's reviews for the {{model}}",
            "title": "Reviews for the {{model}}",
            "weAreLookingForReviews": "Help other people who want to know your opinion",
            "liked": "The best:",
            "disliked": "The worst:",
            "averageTitle": "Average from {{count}} reviews:",
            "summary": {
                "negative": "⛔ Most mentioned negative aspect",
                "positive": "✅ Most mentioned positive aspect"
            }
        },
        "modelTrims": {
            "feedbackMistakeInvitation": "Was this vehicle sold in some other year? Did you find a mistake?&nbsp; ",
            "subtitle": "Years in  which {{make}} {{model}} was sold in {{country}} ",
            "subtitleNotInCountry": "We don't have information for {{make}} {{model}} in {{country}}",
            "title": "{{make}} {{model}} trims in {{country}}",
            "titleGlobal": "{{make}} {{model}} trims",
            "trimInCountry": "{{make}} {{model}} in {{country}}",
            "versions": "{{model}} trims for <span class='year'>{{year}}</span>"
        },
        "modelYearTrims": {
            "feedbackMistakeInvitation": "Are we missing a trim? Did you find a mistake? &nbsp;",
            "seeOtherYears": "For other years click here",
            "subtitle": "{{year}} {{make}} {{model}} trims sold in {{country}}",
            "subtitleYearNotSold": "<strong>{{year}}</strong> {{make}} {{model}} was not sold in {{country}} ",
            "title": "{{year}} {{model}} trims in {{country}}",
            "trimsInOtherCountries": "{{model}} trims in other countries:"
        },
        "scoreChanges": {
            "title": "Thank you for contributing {{username}}!",
            "subTitle": "You recently added",
            "rankingUp": "You just earned <strong>{{scorePhrase}}</strong> and climbed <strong>{{position}}&nbsp;{{positionPhrase}}</strong> in the <a href='{{rankingUrl}}'>members ranking</a>",
            "positionTitle": "You are in <br>position",
            "positionText": "You just earned <strong>{{score}}&nbsp;{{scorePhrase}}</strong> for the <a href='{{rankingUrl}}'>members ranking</a>",
            "congratulations": "Congratulations!",
            "points": {
                "one": "point",
                "other": "points"
            },
            "positions": {
                "one": "position",
                "other": "positions"
            },
            "cause": {
                "commentsMarkedAsSolutionCount": {
                    "one": "reply marked as solution",
                    "other": "replies marked as solutions"
                },
                "commentsVotesPositive": {
                    "one": "helpful comment vote",
                    "other": "helpful comment votes"
                },
                "denunciasPlusOnes": {
                    "one": "owner with the same issue",
                    "other": "owners with the same issue"
                },
                "denunciasVotesPositive": {
                    "one": "useful report vote",
                    "other": "useful report votes"
                },
                "contributionsApproved": {
                    "one": "contribution",
                    "other": "contributions"
                },
                "commentsCount": {
                    "one": "reply",
                    "other": "replies"
                }
            }
        },
        "modeloCommunity": {
            "infographic": {
                "fuseboxDiagram_line1": "fuse box",
                "fuseboxDiagram_line2": "diagrams"
            },
            "lastPosts": "Last questions",
            "postsWithoutComments": "Top questions without answers",
            "title": "{{make}} {{model}} community"
        },
        "infoFuseboxIndividual": {
            "askForHelp": "Ask for help",
            "locationDescriptionMoreThanOneBox": "This {{model}} has {{boxesCount}} different fuse boxes where the {{fuse}} is located:",
            "locationTitle": "{{year}} {{make}} {{model}} {{fuse}} location",
            "relatedProblems": "Common issues with the {{fuse}}",
            "stillHaveDoubts": "Still have doubts about your issue?<br>Ask the community!",
            "filteredTableHeader": "Fuses and relays related to {{fuseEntity}}:",
            "linkFullFusebox": "View full list of fuses and relays...",
            "problemsLink": "View problems with the {{fuse}}"
        }
    },
    "breadcrumbs": {
        "home": "Home",
        "addProblem": "Add a problem",
        "compare": "Comparisons",
        "compareTwoModels": "Compare two models",
        "forgoPassword": "Forgot my password",
        "login": "Login to my account",
        "newPassword": "New password",
        "pickBrand": "Select a Make",
        "pickModel": "Select a Model",
        "search": "Search",
        "shortProblems": "Problems",
        "signUp": "Join StartMyCar",
        "warningLights": "Warning lights",
        "fuseBox": "Fuse box",
        "ownersManual": "Owner's Manuals",
        "modelOverview": "Useful information",
        "myAccount": "My account",
        "ownersManualUpload": "Upload",
        "guides": "Guides",
        "longProblems": "Problems and issues",
        "reviews": "Reviews",
        "trims": "Trims",
        "ownersManualShort": "Manuals",
        "manualsRepairOther": "Service",
        "community": "Community"
    },
    "menu": {
        "help": "Help",
        "modelCompare": "Comparisons",
        "modelOverview": "Useful information",
        "modelProblems": "Problems <br>and issues",
        "infoWarningLights": "Warning Lights",
        "infoOwnersManual": "Owner's Manuals",
        "infoServiceManual": "Service and Repair Manuals",
        "infoFuseBox": "Fuse box diagrams",
        "infoGuides": "Useful guides",
        "changeModel": "Other",
        "modelProblems2": "Problems and issues",
        "pickModel": "Select a model",
        "search": "Search",
        "modelReviews": "Owner's reviews",
        "modelCompareShort": "Compare",
        "modelOverviewShort": "Info",
        "modelProblemsShort": "Problems",
        "modelReviewsShort": "Reviews",
        "infoTrims": "{{model}} trims",
        "modelCommunity": "Community"
    },
    "share": {
        "facebook": {
            "locale": "en_US",
            "pageURL": "https://www.facebook.com/startmycar",
            "buttonTitle": "Share on Facebook",
            "buttonText": "Share",
            "feedTitle": "Can you fix a %s? Click here!",
            "menuLikeText": "Do you like StartMyCar?<br>Follow us on Facebook",
            "footerLikeText": "Do you like StartMyCar? Follow us on Facebook"
        }
    },
    "surveys": {
        "qSurvey1": "Did you find the website helpful?",
        "usefulInformation": "Was this information useful?",
        "thanks": "Thank you!",
        "meh": "Meh...",
        "no": "No!",
        "yes": "Yes!"
    },
    "legal": {
        "disclaimerProfessionals": "<strong>StartMyCar</strong> has nothing to do with any of the listed professionals or particulars. None of the authors, contributors, administrators, vandals, or anyone else connected with StartMyCar, in any way whatsoever, can be responsible for your use of the information contained in or linked from these web pages."
    },
    "lang": {
        "en": "english",
        "es": "spanish",
        "pt": "portuguese",
        "language": "Language"
    },
    "countryCodeToName": {
        "af": "Afghanistan",
        "ax": "Åland Islands",
        "al": "Albania",
        "dz": "Algeria",
        "as": "American Samoa",
        "ad": "Andorra",
        "ao": "Angola",
        "ai": "Anguilla",
        "aq": "Antarctica",
        "ag": "Antigua and Barbuda",
        "ar": "Argentina",
        "am": "Armenia",
        "aw": "Aruba",
        "au": "Australia",
        "at": "Austria",
        "az": "Azerbaijan",
        "bs": "Bahamas",
        "bh": "Bahrain",
        "bd": "Bangladesh",
        "bb": "Barbados",
        "by": "Belarus",
        "be": "Belgium",
        "bz": "Belize",
        "bj": "Benin",
        "bm": "Bermuda",
        "bt": "Bhutan",
        "bo": "Bolivia",
        "bq": "Bonaire, Sint Eustatius and Saba",
        "ba": "Bosnia and Herzegovina",
        "bw": "Botswana",
        "bv": "Bouvet Island",
        "br": "Brazil",
        "io": "British Indian Ocean Territory",
        "bn": "Brunei Darussalam",
        "bg": "Bulgaria",
        "bf": "Burkina Faso",
        "bi": "Burundi",
        "kh": "Cambodia",
        "cm": "Cameroon",
        "ca": "Canada",
        "cv": "Cape Verde",
        "ky": "Cayman Islands",
        "cf": "Central African Republic",
        "td": "Chad",
        "cl": "Chile",
        "cn": "China",
        "cx": "Christmas Island",
        "cc": "Cocos (Keeling) Islands",
        "co": "Colombia",
        "km": "Comoros",
        "cg": "Congo",
        "cd": "Congo, the Democratic Republic of the",
        "ck": "Cook Islands",
        "cr": "Costa Rica",
        "ci": "Côte d'Ivoire",
        "hr": "Croatia",
        "cu": "Cuba",
        "cw": "Curaçao",
        "cy": "Cyprus",
        "cz": "Czech Republic",
        "dk": "Denmark",
        "dj": "Djibouti",
        "dm": "Dominica",
        "do": "Dominican Republic",
        "ec": "Ecuador",
        "eg": "Egypt",
        "sv": "El Salvador",
        "gq": "Equatorial Guinea",
        "er": "Eritrea",
        "ee": "Estonia",
        "et": "Ethiopia",
        "fk": "Falkland Islands (Malvinas)",
        "fo": "Faroe Islands",
        "fj": "Fiji",
        "fi": "Finland",
        "fr": "France",
        "gf": "French Guiana",
        "pf": "French Polynesia",
        "tf": "French Southern Territories",
        "ga": "Gabon",
        "gm": "Gambia",
        "ge": "Georgia",
        "de": "Germany",
        "gh": "Ghana",
        "gi": "Gibraltar",
        "gr": "Greece",
        "gl": "Greenland",
        "gd": "Grenada",
        "gp": "Guadeloupe",
        "gu": "Guam",
        "gt": "Guatemala",
        "gg": "Guernsey",
        "gn": "Guinea",
        "gw": "Guinea-Bissau",
        "gy": "Guyana",
        "ht": "Haiti",
        "hm": "Heard Island and McDonald Islands",
        "va": "Holy See (Vatican City State)",
        "hn": "Honduras",
        "hk": "Hong Kong",
        "hu": "Hungary",
        "is": "Iceland",
        "in": "India",
        "id": "Indonesia",
        "ir": "Iran",
        "iq": "Iraq",
        "ie": "Ireland",
        "im": "Isle of Man",
        "il": "Israel",
        "it": "Italy",
        "jm": "Jamaica",
        "jp": "Japan",
        "je": "Jersey",
        "jo": "Jordan",
        "kz": "Kazakhstan",
        "ke": "Kenya",
        "ki": "Kiribati",
        "kp": "North Korea",
        "kr": "South Korea",
        "kw": "Kuwait",
        "kg": "Kyrgyzstan",
        "la": "Lao People's Democratic Republic",
        "lv": "Latvia",
        "lb": "Lebanon",
        "ls": "Lesotho",
        "lr": "Liberia",
        "ly": "Libya",
        "li": "Liechtenstein",
        "lt": "Lithuania",
        "lu": "Luxembourg",
        "mo": "Macao",
        "mk": "Macedonia, the Former Yugoslav Republic of",
        "mg": "Madagascar",
        "mw": "Malawi",
        "my": "Malaysia",
        "mv": "Maldives",
        "ml": "Mali",
        "mt": "Malta",
        "mh": "Marshall Islands",
        "mq": "Martinique",
        "mr": "Mauritania",
        "mu": "Mauritius",
        "yt": "Mayotte",
        "mx": "Mexico",
        "fm": "Micronesia",
        "md": "Moldova",
        "mc": "Monaco",
        "mn": "Mongolia",
        "me": "Montenegro",
        "ms": "Montserrat",
        "ma": "Morocco",
        "mz": "Mozambique",
        "mm": "Myanmar",
        "na": "Namibia",
        "nr": "Nauru",
        "np": "Nepal",
        "nl": "Netherlands",
        "nc": "New Caledonia",
        "nz": "New Zealand",
        "ni": "Nicaragua",
        "ne": "Niger",
        "ng": "Nigeria",
        "nu": "Niue",
        "nf": "Norfolk Island",
        "mp": "Northern Mariana Islands",
        "no": "Norway",
        "om": "Oman",
        "pk": "Pakistan",
        "pw": "Palau",
        "ps": "Palestine",
        "pa": "Panama",
        "pg": "Papua New Guinea",
        "py": "Paraguay",
        "pe": "Peru",
        "ph": "Philippines",
        "pn": "Pitcairn",
        "pl": "Poland",
        "pt": "Portugal",
        "pr": "Puerto Rico",
        "qa": "Qatar",
        "re": "Réunion",
        "ro": "Romania",
        "ru": "Russian",
        "rw": "Rwanda",
        "bl": "Saint Barthélemy",
        "sh": "Saint Helena, Ascension and Tristan da Cunha",
        "kn": "Saint Kitts and Nevis",
        "lc": "Saint Lucia",
        "mf": "Saint Martin (French part)",
        "pm": "Saint Pierre and Miquelon",
        "vc": "Saint Vincent and the Grenadines",
        "ws": "Samoa",
        "sm": "San Marino",
        "st": "Sao Tome and Principe",
        "sa": "Saudi Arabia",
        "sn": "Senegal",
        "rs": "Serbia",
        "sc": "Seychelles",
        "sl": "Sierra Leone",
        "sg": "Singapore",
        "sx": "Sint Maarten (Dutch part)",
        "sk": "Slovakia",
        "si": "Slovenia",
        "sb": "Solomon Islands",
        "so": "Somalia",
        "za": "South Africa",
        "gs": "South Georgia and the South Sandwich Islands",
        "ss": "South Sudan",
        "es": "Spain",
        "lk": "Sri Lanka",
        "sd": "Sudan",
        "sr": "Suriname",
        "sj": "Svalbard and Jan Mayen",
        "sz": "Swaziland",
        "se": "Sweden",
        "ch": "Switzerland",
        "sy": "Syrian Arab Republic",
        "tw": "Taiwan",
        "tj": "Tajikistan",
        "tz": "Tanzania",
        "th": "Thailand",
        "tl": "Timor-Leste",
        "tg": "Togo",
        "tk": "Tokelau",
        "to": "Tonga",
        "tt": "Trinidad and Tobago",
        "tn": "Tunisia",
        "tr": "Turkey",
        "tm": "Turkmenistan",
        "tc": "Turks and Caicos Islands",
        "tv": "Tuvalu",
        "ug": "Uganda",
        "ua": "Ukraine",
        "ae": "United Arab Emirates",
        "gb": "United Kingdom",
        "us": "United States",
        "um": "United States Minor Outlying Islands",
        "uy": "Uruguay",
        "uz": "Uzbekistan",
        "vu": "Vanuatu",
        "ve": "Venezuela",
        "vn": "Viet Nam",
        "vg": "Virgin Islands, British",
        "vi": "Virgin Islands, U.S.",
        "wf": "Wallis and Futuna",
        "eh": "Western Sahara",
        "ye": "Yemen",
        "zm": "Zambia",
        "zw": "Zimbabwe",
        "other": "other",
        "global": "International",
        "eu": "European Union"
    },
    "countryNameToCode": {
        "afghanistan": "af",
        "aland islands": "ax",
        "albania": "al",
        "algeria": "dz",
        "american samoa": "as",
        "andorra": "ad",
        "angola": "ao",
        "anguilla": "ai",
        "antarctica": "aq",
        "antigua and barbuda": "ag",
        "argentina": "ar",
        "armenia": "am",
        "aruba": "aw",
        "australia": "au",
        "austria": "at",
        "azerbaijan": "az",
        "bahamas": "bs",
        "bahrain": "bh",
        "bangladesh": "bd",
        "barbados": "bb",
        "belarus": "by",
        "belgium": "be",
        "belize": "bz",
        "benin": "bj",
        "bermuda": "bm",
        "bhutan": "bt",
        "bolivia": "bo",
        "bonaire, sint eustatius and saba": "bq",
        "bosnia and herzegovina": "ba",
        "botswana": "bw",
        "bouvet island": "bv",
        "brazil": "br",
        "british indian ocean territory": "io",
        "brunei darussalam": "bn",
        "bulgaria": "bg",
        "burkina faso": "bf",
        "burundi": "bi",
        "cambodia": "kh",
        "cameroon": "cm",
        "canada": "ca",
        "cape verde": "cv",
        "cayman islands": "ky",
        "central african republic": "cf",
        "chad": "td",
        "chile": "cl",
        "china": "cn",
        "christmas island": "cx",
        "cocos (keeling) islands": "cc",
        "colombia": "co",
        "comoros": "km",
        "congo": "cg",
        "congo, the democratic republic of the": "cd",
        "cook islands": "ck",
        "costa rica": "cr",
        "cote d'ivoire": "ci",
        "croatia": "hr",
        "cuba": "cu",
        "curacao": "cw",
        "cyprus": "cy",
        "czech republic": "cz",
        "denmark": "dk",
        "djibouti": "dj",
        "dominica": "dm",
        "dominican republic": "do",
        "ecuador": "ec",
        "egypt": "eg",
        "el salvador": "sv",
        "equatorial guinea": "gq",
        "eritrea": "er",
        "estonia": "ee",
        "ethiopia": "et",
        "falkland islands (malvinas)": "fk",
        "faroe islands": "fo",
        "fiji": "fj",
        "finland": "fi",
        "france": "fr",
        "french guiana": "gf",
        "french polynesia": "pf",
        "french southern territories": "tf",
        "gabon": "ga",
        "gambia": "gm",
        "georgia": "ge",
        "germany": "de",
        "ghana": "gh",
        "gibraltar": "gi",
        "greece": "gr",
        "greenland": "gl",
        "grenada": "gd",
        "guadeloupe": "gp",
        "guam": "gu",
        "guatemala": "gt",
        "guernsey": "gg",
        "guinea": "gn",
        "guinea-bissau": "gw",
        "guyana": "gy",
        "haiti": "ht",
        "heard island and mcdonald islands": "hm",
        "holy see (vatican city state)": "va",
        "honduras": "hn",
        "hong kong": "hk",
        "hungary": "hu",
        "iceland": "is",
        "india": "in",
        "indonesia": "id",
        "iran": "ir",
        "iraq": "iq",
        "ireland": "ie",
        "isle of man": "im",
        "israel": "il",
        "italy": "it",
        "jamaica": "jm",
        "japan": "jp",
        "jersey": "je",
        "jordan": "jo",
        "kazakhstan": "kz",
        "kenya": "ke",
        "kiribati": "ki",
        "north korea": "kp",
        "south korea": "kr",
        "kuwait": "kw",
        "kyrgyzstan": "kg",
        "lao people's democratic republic": "la",
        "latvia": "lv",
        "lebanon": "lb",
        "lesotho": "ls",
        "liberia": "lr",
        "libya": "ly",
        "liechtenstein": "li",
        "lithuania": "lt",
        "luxembourg": "lu",
        "macao": "mo",
        "macedonia, the former yugoslav republic of": "mk",
        "madagascar": "mg",
        "malawi": "mw",
        "malaysia": "my",
        "maldives": "mv",
        "mali": "ml",
        "malta": "mt",
        "marshall islands": "mh",
        "martinique": "mq",
        "mauritania": "mr",
        "mauritius": "mu",
        "mayotte": "yt",
        "mexico": "mx",
        "micronesia": "fm",
        "moldova": "md",
        "monaco": "mc",
        "mongolia": "mn",
        "montenegro": "me",
        "montserrat": "ms",
        "morocco": "ma",
        "mozambique": "mz",
        "myanmar": "mm",
        "namibia": "na",
        "nauru": "nr",
        "nepal": "np",
        "netherlands": "nl",
        "new caledonia": "nc",
        "new zealand": "nz",
        "nicaragua": "ni",
        "niger": "ne",
        "nigeria": "ng",
        "niue": "nu",
        "norfolk island": "nf",
        "northern mariana islands": "mp",
        "norway": "no",
        "oman": "om",
        "pakistan": "pk",
        "palau": "pw",
        "palestine": "ps",
        "panama": "pa",
        "papua new guinea": "pg",
        "paraguay": "py",
        "peru": "pe",
        "philippines": "ph",
        "pitcairn": "pn",
        "poland": "pl",
        "portugal": "pt",
        "puerto rico": "pr",
        "qatar": "qa",
        "reunion": "re",
        "romania": "ro",
        "russian": "ru",
        "rwanda": "rw",
        "saint barthelemy": "bl",
        "saint helena, ascension and tristan da cunha": "sh",
        "saint kitts and nevis": "kn",
        "saint lucia": "lc",
        "saint martin (french part)": "mf",
        "saint pierre and miquelon": "pm",
        "saint vincent and the grenadines": "vc",
        "samoa": "ws",
        "san marino": "sm",
        "sao tome and principe": "st",
        "saudi arabia": "sa",
        "senegal": "sn",
        "serbia": "rs",
        "seychelles": "sc",
        "sierra leone": "sl",
        "singapore": "sg",
        "sint maarten (dutch part)": "sx",
        "slovakia": "sk",
        "slovenia": "si",
        "solomon islands": "sb",
        "somalia": "so",
        "south africa": "za",
        "south georgia and the south sandwich islands": "gs",
        "south sudan": "ss",
        "spain": "es",
        "sri lanka": "lk",
        "sudan": "sd",
        "suriname": "sr",
        "svalbard and jan mayen": "sj",
        "swaziland": "sz",
        "sweden": "se",
        "switzerland": "ch",
        "syrian arab republic": "sy",
        "taiwan": "tw",
        "tajikistan": "tj",
        "tanzania": "tz",
        "thailand": "th",
        "timor-leste": "tl",
        "togo": "tg",
        "tokelau": "tk",
        "tonga": "to",
        "trinidad and tobago": "tt",
        "tunisia": "tn",
        "turkey": "tr",
        "turkmenistan": "tm",
        "turks and caicos islands": "tc",
        "tuvalu": "tv",
        "uganda": "ug",
        "ukraine": "ua",
        "united arab emirates": "ae",
        "united kingdom": "gb",
        "united states": "us",
        "united states minor outlying islands": "um",
        "uruguay": "uy",
        "uzbekistan": "uz",
        "vanuatu": "vu",
        "venezuela": "ve",
        "viet nam": "vn",
        "virgin islands, british": "vg",
        "virgin islands, u.s.": "vi",
        "wallis and futuna": "wf",
        "western sahara": "eh",
        "yemen": "ye",
        "zambia": "zm",
        "zimbabwe": "zw",
        "other": "other",
        "internacional": "global"
    }
};})()